var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c(
        "CCardBody",
        [
          _c(
            "CRow",
            [
              _c("CCol", [
                _c("label", [_vm._v(" UID: ")]),
                _c("div", [_vm._v(" " + _vm._s(_vm.uid_) + " ")])
              ])
            ],
            1
          ),
          _c(
            "CRow",
            [
              _c("CCol", [
                _c("label", [_vm._v(" Name: ")]),
                _c("div", [_vm._v(" " + _vm._s(_vm.name_) + " ")])
              ])
            ],
            1
          ),
          _c(
            "CRow",
            [
              _c("CCol", [
                _c("label", [_vm._v(" Description: ")]),
                _c("div", [_vm._v(" " + _vm._s(_vm.description_) + " ")])
              ])
            ],
            1
          ),
          _c(
            "CRow",
            [
              _c("CCol", [
                _c("label", [_vm._v(" Created: ")]),
                _c("div", [_vm._v(" " + _vm._s(_vm.created_) + " ")])
              ])
            ],
            1
          ),
          _c(
            "CRow",
            [
              _c("CCol", [
                _c("label", [_vm._v(" Updated: ")]),
                _c("div", [_vm._v(" " + _vm._s(_vm.updated_) + " ")])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCardFooter",
        [
          _c("CButton", { on: { click: _vm.setCurrToActive } }, [
            _vm._v(" Update ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }