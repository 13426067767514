var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "12" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", [
                _c(
                  "div",
                  { staticClass: "default-card-header" },
                  [
                    _c("CIcon", { attrs: { name: "cil-bell" } }),
                    _vm._v(" Device Group List View ")
                  ],
                  1
                )
              ]),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { xs: "5", sm: "5" } },
                        [
                          _c("CInput", {
                            attrs: { placeholder: "Search for a Device Group" },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c(
                                      "CButton",
                                      { attrs: { color: "info" } },
                                      [
                                        _c("CIcon", {
                                          attrs: {
                                            name: "cil-magnifying-glass"
                                          }
                                        }),
                                        _vm._v(" Search ")
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        [
                          _c(
                            "CButton",
                            {
                              staticClass: "float-right",
                              attrs: { color: "info", variant: "outline" },
                              on: { click: _vm.addDeviceGroupClicked }
                            },
                            [_vm._v(" + Add Device Group ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { lg: "12", md: "12" } },
                        _vm._l(_vm.deviceGroups, function(deviceGroup) {
                          return _c("DeviceGroupCard", {
                            key: deviceGroup.uid,
                            staticClass: "w-100 mb-2",
                            attrs: { currUID: deviceGroup.uid },
                            on: { setActiveUID: _vm.setActiveDeviceGroupUID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CModal", {
        attrs: {
          show: _vm.isConfigActive,
          centered: "",
          fade: "",
          color: "",
          "border-color": "secondary",
          title: (_vm.flag_adding ? "Add" : "Configure") + " Device Group",
          size: "lg"
        },
        on: {
          "update:show": function($event) {
            _vm.isConfigActive = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body-wrapper",
            fn: function() {
              return [
                _c("DeviceGroupConfig", {
                  staticClass: "m-0 p-0",
                  attrs: {
                    flag_adding: _vm.flag_adding,
                    currUID: _vm.activeDeviceGroupUID,
                    flag_update: _vm.flag_updateDeviceGroup
                  },
                  on: {
                    validationStatusChanged:
                      _vm.deviceGroupValidationStatusChanged
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c("CButton", { on: { click: _vm.onModalCanceled } }, [
                      _vm._v(" Cancel ")
                    ]),
                    _c(
                      "CButton",
                      {
                        attrs: {
                          color: "success",
                          disabled: !_vm.deviceGroupConfig_validated
                        },
                        on: { click: _vm.onModalConfirmed }
                      },
                      [_vm._v(" Save ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }