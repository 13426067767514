var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h5", [_vm._v("Device Group")]),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Name")]),
        _c("CInput", {
          attrs: {
            value: _vm.name_,
            isValid: !_vm.$v.name_.$invalid,
            invalidFeedback: _vm.invalidFeedback("name_")
          },
          on: {
            "update:value": function($event) {
              _vm.name_ = $event
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c("label", [_vm._v("Description")]),
        _c("CTextarea", {
          attrs: {
            value: _vm.description_,
            isValid: !_vm.$v.description_.$invalid,
            invalidFeedback: _vm.invalidFeedback("description_")
          },
          on: {
            "update:value": function($event) {
              _vm.description_ = $event
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c("label", [_vm._v("Device UIDs")]),
        _c("SingleDropdown", {
          attrs: {
            selectedIDs: _vm.devices_uids_,
            options: _vm.devices_options,
            trackBy: "uid",
            label: "name",
            placeholder: "Select Devices"
          },
          on: { selectedIDsChanged: _vm.setDevicesUIDs }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }