<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol>
          <label> UID: </label>
          <div> 
            {{uid_}}
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <label> Name: </label>
          <div> 
            {{name_}}
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <label> Description: </label>
          <div> 
            {{description_}}
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <label> Created: </label>
          <div> 
            {{created_}}
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <label> Updated: </label>
          <div> 
            {{updated_}}
          </div>
        </CCol>
      </CRow>
    </CCardBody> 
    <CCardFooter> 
      <CButton @click="setCurrToActive"> Update </CButton>
    </CCardFooter>
  </CCard>
</template>



<style scoped>
</style>


<script>
import { mapGetters } from 'vuex'

export default {
  name: "DeviceGroupCard",
  props: {
    currUID: String,
  },
  data() {
    return {
      uid_: "",
      name_: "",
      description_: "",
      created_: "",
      updated_: "",
    };
  },
  computed: {
    ...mapGetters({
      deviceGroups: 'deviceGroups',
    }),

    deviceGroup() {
      return this.deviceGroups.find(deviceGroup => {
        return deviceGroup.uid == this.currUID;
      })
    },
  },
  mounted() {},
  methods: {
    populateFields() {
      if (!this.deviceGroup) {
        return;
      } 
      this.uid_ = this.deviceGroup.uid;
      this.name_ = this.deviceGroup.name;
      this.description_ = this.deviceGroup.description;
      this.created_ = this.deviceGroup.created;
      this.updated_ = this.deviceGroup.updated;
    },

    setCurrToActive() {
      this.$emit("setActiveUID", this.currUID);
    },
  },
  watch: {
    deviceGroup: {
      immediate: true,
      handler () {
        this.populateFields();
      }
    },
  }
};
</script>