<template>
  <CRow>
    <CCol md="12">
      <CCard>
        <CCardHeader>
          <div class="default-card-header">
            <CIcon name="cil-bell"> </CIcon>
            Device Group List View
          </div>
        </CCardHeader>

        <CCardBody>
          <CRow>
            <CCol xs="5" sm="5">
              <CInput placeholder="Search for a Device Group">
                <template #append>
                  <CButton color="info">
                    <CIcon name="cil-magnifying-glass" /> Search
                  </CButton>
                </template>
              </CInput>
            </CCol>
            <CCol>
              <CButton
                color="info"
                variant="outline"
                class="float-right"
                @click="addDeviceGroupClicked"
              >
                + Add Device Group
              </CButton>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="12" md="12">
              <DeviceGroupCard
                class="w-100 mb-2"
                v-for="deviceGroup of deviceGroups"
                :key="deviceGroup.uid"
                :currUID="deviceGroup.uid"
                @setActiveUID="setActiveDeviceGroupUID"
              >
              </DeviceGroupCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <CModal
      :show.sync="isConfigActive"
      centered
      fade
      color=""
      border-color="secondary"
      :title="(flag_adding? 'Add': 'Configure') + ' Device Group'"
      size="lg"
    >
      <template #body-wrapper>
        <DeviceGroupConfig
            class="m-0 p-0"
            :flag_adding="flag_adding"
            :currUID="activeDeviceGroupUID"
            :flag_update="flag_updateDeviceGroup"
            @validationStatusChanged="deviceGroupValidationStatusChanged"
          >
        </DeviceGroupConfig>
      </template>

      <template #footer>
        <div>
          <CButton @click="onModalCanceled"> Cancel </CButton>
          <CButton color="success" @click="onModalConfirmed" :disabled="!deviceGroupConfig_validated"> Save </CButton>
        </div>
      </template>
    </CModal>
  </CRow>
</template>

<style scoped>
</style>


<script>
import DeviceGroupCard from "@/components/deviceGroups/DeviceGroupCard";
import DeviceGroupConfig from "@/components/deviceGroups/DeviceGroupConfig";

import { mapGetters } from 'vuex'

export default {
  name: "DeviceGroupListView",
  components: {
    DeviceGroupCard,
    DeviceGroupConfig,
  },
  props: {
  },
  data() {
    return {
      activeDeviceGroupUID: null,
      flag_adding: false,
      flag_updateDeviceGroup: 0,
      deviceGroupConfig_validated: false,
    };
  },

  computed: {
    ...mapGetters({
      deviceGroups: 'deviceGroups',
    }),
    
    isConfigActive: {
      get () {
        console.log("isConfigActive", this.activeDeviceGroupUID);
        return this.activeDeviceGroupUID || this.flag_adding;
      },

      set (bool) {
        if (!bool) {
          this.activeDeviceGroupUID = null;
          this.flag_adding = null;
        }
      }
    },
  },
  methods: {
    setActiveDeviceGroupUID (uid) {
      return this.activeDeviceGroupUID = uid;
    },

    onModalCanceled () {
      this.activeDeviceGroupUID = null;
    },

    onModalConfirmed () {
      this.flag_updateDeviceGroup = (this.flag_updateDeviceGroup + 1) % 2; // to trigger child watcher.
      this.$nextTick(() => { // update first, then nullify the active device group.
        this.activeDeviceGroupUID = null;
      }) 
    },
    
    addDeviceGroupClicked() {
      this.flag_adding = true;
    },

    deviceGroupValidationStatusChanged (bool) {
      this.deviceGroupConfig_validated = bool;
    }
  },

  async mounted() {
    console.log("Loading Device Groups");
    await this.$app.loadDeviceGroups();
  },
};
</script>