<template>
  <div>
    <h5>Device Group</h5>
    <div class="form-group">
      <label>Name</label>
      <CInput
        :value.sync="name_"
        :isValid="!$v.name_.$invalid"
        :invalidFeedback="invalidFeedback('name_')"
      />
    </div>
    <div> 
      <label>Description</label>
      <CTextarea
        :value.sync="description_"
        :isValid="!$v.description_.$invalid"
        :invalidFeedback="invalidFeedback('description_')"
      />
    </div>
    <div> 
      <label>Device UIDs</label>
      <SingleDropdown
        :selectedIDs="devices_uids_" 
        :options="devices_options"
        trackBy="uid"
        label="name"
        @selectedIDsChanged="setDevicesUIDs"
        placeholder="Select Devices"
      >
      </SingleDropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SingleDropdown from "@/components/filters/SingleDropdown"

import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
} from "vuelidate/lib/validators";

const MAX_LENGTH = {
  name_: 60,
  description_: 300,
}

export default {
  name: "DeviceGroupConfig",
  components: {
    SingleDropdown,
  },
  props: {
    currUID: String,
    flag_adding: Boolean,
  },
  computed: {
    ...mapGetters({
      deviceGroups: 'deviceGroups',
    }),

    // custom
    devices () {
      return this.$app.activeDevices || [];
    },

    validated() {
      return !this.$v.$invalid;
    },

    deviceGroup () {
      if (!this.currUID) {
        return null;
      }
      return this.deviceGroups.find(item => {
        return item.uid == this.currUID;
      })
    },

    devices_options() {
      return this.devices.map(device => {
        return {
          name: device.name,
          uid: device.id // replace with `uid: device.uid`
        }
      }) 
    },
  },
  data() {
    return {
      name_: "",
      description_: "",
      devices_uids_: [],
    };
  },
  methods: {
    populateFields() {
      if (!this.deviceGroup) {
        this.name_ = null;
        this.description_ = null;
        this.devices_uids_ = null;
      }
      this.name_ = this.deviceGroup.name;
      this.description_ = this.deviceGroup.description;
      this.devices_uids_ = this.deviceGroup.devices_uids;
    },

    async submit() {
      if (this.flag_adding) {
        console.log("API action: POST DeviceGroup")
        await this.$app.addDeviceGroup(null, { // h_todo_lonestar_101: make this function
          name: this.name_,
          description: this.description_,
          devices_uids: this.devices_uids_,
        });
      } else {
        console.log("API action: PUT DeviceGroup")
        await this.$app.modifyDeviceGroup(this.currUID, { // h_todo_lonestar_101: make this function
          name: this.name_,
          description: this.description_,
          devices_uids: this.devices_uids_,
        });
      }
    },

    invalidFeedback (fieldName) {
      if (!this.$v[fieldName].$invalid) {
        return '';
      }
      if (this.$v[fieldName].required == false) {
        return "Cannot be empty";
      } else if (this.$v[fieldName].email == false) {
        return `Must be an email`;
      } else if (this.$v[fieldName].maxLengthValue == false) {
        return `Cannot exceed length of ${MAX_LENGTH[fieldName]}`;
      } else {
        console.log("Need to handle this error case: ", this.$v[fieldName]);
        return 'Error';
      }
    },

    setDevicesUIDs (uids) {
      this.devices_uids_ = uids;
    },
  },
  watch: {
    deviceGroup: {
      immediate: true,
      handler () {
        this.populateFields();
      }
    }
  },
  validations: {
    name_: {
      required,
      maxLengthValue: maxLength(MAX_LENGTH.name_),      
    },
    description_: {
      required,
      maxLengthValue: maxLength(MAX_LENGTH.description_)
    },
  },
  mixins: [validationMixin],
};
</script>